

export const firebaseConfig = {
  apiKey: "AIzaSyBmJ51QUF0_Ye6CuEvgyVW-jcsSB0G_a4A",
  authDomain: "free-bank-40f39.firebaseapp.com",
  projectId: "free-bank-40f39",
  storageBucket: "free-bank-40f39.appspot.com",
  messagingSenderId: "26739509620",
  appId: "1:26739509620:web:c86dc4af40f445bb108421",
  measurementId: "G-T1DW83WXRB"
};